<template>
    <div style="background-color: #ffffff;padding: 20px 50px;">
        <div class="pageCenter text-left m-b-20">
            <div class="f24 bold text-center" style="padding-top: 20px;">订单详情</div>
            <div class="m-b-40 m-t-50">

              <template v-if="orderRecycle && orderRecycle.length > 0">
                <el-steps :active="orderRecycle.length + 1" align-center>
                  <template v-for="item in orderRecycle">
                    <template v-if="makeLifeCycleTxt(item.order_life_cycle_action)">
                      <el-step :title="makeLifeCycleTxt(item.order_life_cycle_action).txt" :icon="makeLifeCycleTxt(item.order_life_cycle_action).icon" :description="item.order_life_cycle_create_time"></el-step>
                    </template>
                  </template>
                </el-steps>
              </template>

            </div>
            <lineTitle text="订单信息"></lineTitle>
            <div class="m-b-40">
                <order :orderData="orderData" class="m-t-20"></order>
            </div>
            <div class="text-center m-t-20 p-b-50">
                <el-button type="primary" @click="return_order_list">返回订单列表</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            input: null,
            orderId: null,
            splitOrder: null,
            orderData: {},
            detail:{},
            orderRecycle: []
        }
    },
    computed: {

    },
    mounted() {
        this.orderId = this.$route.query.orderId
        this.splitOrder = this.$route.query.splitOrder=='true'?true:false
        this.queryOrderDetail()
    },
    methods: {
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            // me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            // me.queryFurnitureLibrary();
        },
        queryOrderDetail() {
            schemeOrder.queryOrderDetail({ orderId: this.orderId, splitOrder: this.splitOrder }).then(res => {
                this.orderData = res
                this.detail=res&&res.orderSchemes&&res.orderSchemes[0]
                if(this.detail && this.detail.orderLifeCycle && this.detail.orderLifeCycle.length > 0){
                  this.orderRecycle = JSON.parse(this.detail.orderLifeCycle);
                }

            })
        },
        return_order_list: function (){
            this.$oucy.back()
        },
        makeLifeCycleTxt: function (action){
          switch (action) {
            case 1:
            {
              return {
                txt: "已下单",
                icon: "el-icon-sold-out"
              };
            }
            case 105:
            {
              return {
                txt: "已付款",
                icon: "el-icon-s-finance"
              };
            }
            case 3:
            {
              return {
                txt: "已发货",
                icon: "el-icon-truck"
              };
            }
            case 4:
            {
              return {
                txt: "已取消",
                icon: "el-icon-error"
              };
            }
            case 5:
            {
              return {
                txt: "已收货",
                icon: "el-icon-s-goods"
              };
            }
            case 200:
            {
              return {
                txt: "已完成",
                icon: "el-icon-s-claim"
              };
            }
            default:
            {
              return null;
            }
          }
        }
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}
.slotimg{
    height: 35px;
}
</style>